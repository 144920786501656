import { Component, Input, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "app-content-details-form",
  templateUrl: "./content-details-form.component.html",
  styleUrls: ["./content-details-form.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class ContentDetailsFormComponent  { 
  @Input() Text : string;
}
