import { Component, OnInit } from '@angular/core';
import { UserService, HtmlService } from './core';
import { Router, NavigationEnd } from '@angular/router';

declare let gtag: Function;
declare let fbq: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  PathName : string;

  constructor(
    private _htmlService: HtmlService,
    private _userService: UserService,
    private _router: Router
  ) {   
    this._router.events.subscribe((y: NavigationEnd) => {
      if (y instanceof NavigationEnd) {
        // gtag('config', 'G-7Z45J8B073', { 'page_path': y.url });
        // fbq('track', 'PageView');
      }
    });
  }

  ngOnInit() {
    this._htmlService.UpdateMetaTags({ title: 'Home' });
    this._userService.populate();
    this.PathName = window.location.pathname + window.location.search;
  }

  ngAfterViewInit() {
    if(this.PathName.includes('login') || this.PathName.includes('register') || this.PathName.includes('activation')) {
      this._router.navigateByUrl(this.PathName);
    }
  }
}
