import { Component, OnInit, Input, Output, EventEmitter} from "@angular/core";
import { Product } from "../../../core/models";

@Component({
  selector: "app-product-big-image",
  templateUrl: "./image-big-product.component.html",
  styleUrls: ["./image-big-product.component.css"]
})
export class ProductBigImageComponent implements OnInit {  
  @Input() Model: Product;  
  @Input() IsWish: boolean;  
  @Output() OnWishlist = new EventEmitter();

  Ratings: string[];

  constructor(
  ) {
  }

  ngOnInit() { 
  }

  onWishlist() {
    this.OnWishlist.emit();
  }
}
