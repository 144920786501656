import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Observable } from "rxjs";
import { DTO } from "../models";

@Injectable({
  providedIn: "root"
})
export class ShopService {
  constructor(private apiService: ApiService) { }

  syncerOrderUpdate(): Observable<any> {
    return this.apiService.get(`Api/data/updateorder`);
  }

  syncerDBUpdate(): Observable<any> {
    return this.apiService.get(`Api/data/updatedb`);
  }

  syncerProductUpdate(): Observable<any> {
    return this.apiService.get(`Api/data/updateproduct`);
  }

  syncerOrderDelete(): Observable<any> {
    return this.apiService.get(`Api/data/deleteorder`);
  }

  syncerOrderBackUpDelete(): Observable<any> {
    return this.apiService.get(`Api/data/deleteorderbackup`);
  }

  syncerDBDelete(): Observable<any> {
    return this.apiService.get(`Api/data/deletedb`);
  }  

  syncerProductDelete(): Observable<any> {
    return this.apiService.get(`Api/data/deleteproduct`);
  }  

  syncerOrderEmail(): Observable<any> {
    return this.apiService.get(`Api/data/emailorder`);
  } 
  
  syncerOrderMissing(): Observable<any> {
    return this.apiService.get(`Api/data/missingorder`);
  } 

  getFilter(): Observable<any> {
    return this.apiService.get(`Api/lookup/filter`);
  }

  getAnnouncement(): Observable<any> {
    return this.apiService.get(`Api/lookup/announcement`);
  }

  getMaintenance(): Observable<any> {
    return this.apiService.get(`Api/lookup/maintenance`);
  }

  getCarousell(): Observable<any> {
    return this.apiService.get(`Api/lookup/carousell`);
  }

  getCourier(): Observable<any> {
    return this.apiService.get(`Api/lookup/courier`);
  }

  getPayment(): Observable<any> {
    return this.apiService.get(`Api/lookup/payment`);
  }

  getSizeGuide(): Observable<any> {
    return this.apiService.get(`Api/lookup/sizeguide`);
  }

  getRegion(): Observable<any> {
    return this.apiService.get(`Api/address/region`);
  }

  getCity(id: any): Observable<any> {
    return this.apiService.get(`Api/address/city/${id}`);
  }

  getCategory(): Observable<any> {
    return this.apiService.get(`Api/category/lists`);
  }

  getTag(): Observable<any> {
    return this.apiService.get(`Api/category/tags`);
  }

  getProductList(item): Observable<any> {
    return this.apiService.get(`Api/product/lists?limit=${item.limit}&offset=${item.offset}`);
  }

  getProductNew(): Observable<any> {
    return this.apiService.get(`Api/product/new`);
  }

  getProductInfo(id: any): Observable<any> {
    return this.apiService.get(`Api/product/info/${id}`);
  }

  getProductViewCount(id: any): Observable<any> {
    return this.apiService.get(`Api/product/view/${id}`);
  }

  getProductWildcard(item): Observable<any> {
    let record = this.getFilterPayload(item);
    return this.apiService.get(`Api/product/wildcard?limit=${record.limit}&offset=${record.offset}&data=${record.data}`);
  }

  getProductFilter(item): Observable<any> {
    let record = this.getFilterPayload(item);
    return this.apiService.get(`Api/product/filter?limit=${record.limit}&offset=${record.offset}&data=${record.data}`);
  }

  getProductRange(item): Observable<any> {
    let record = this.getFilterPayload(item);
    return this.apiService.get(`Api/product/range?limit=${record.limit}&offset=${record.offset}&data=${record.data}`);
  }

  getOrderStatus(item): Observable<any> {
    let data = JSON.stringify(item);
    return this.apiService.get(`Api/customer/track/order?data=${data}`);
  }

  postCustomerOrder(item): Observable<any> {
    return this.apiService.post(`Api/customer/order`, item);
  }

  postSubscription(item): Observable<any> {
    return this.apiService.post(`Api/customer/subscription`, item);
  }

  getFilterPayload(item) {
    let limit = item.limit;
    let offset = item.offset;
    delete item.limit;
    delete item.offset;

    return {
      data : JSON.stringify(item),
      limit: limit,
      offset: offset
    };
  }
}
