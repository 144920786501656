import { Component, OnInit, Input, Output, EventEmitter} from "@angular/core";
import { User } from "../../../../core/models";
import { HtmlService } from "../../../../core/services";

@Component({
  selector: "app-sidebar-overlay",
  templateUrl: "./sidebar-overlay.component.html",
  styleUrls: ["./sidebar-overlay.component.css"]
})
export class SideBarOverlayComponent implements OnInit {  
  @Input() User: User;
  @Input() IsAdmin: boolean = false;  

  constructor(
    private _htmlService: HtmlService
  ) {  
  }

  ngOnInit() { 
  }

  onClose() {
    setTimeout(() => this._htmlService.OverlayRemove(), 100);
  }
}
