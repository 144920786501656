import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class AccountService {
  constructor(private apiService: ApiService) { }
 
  getInfo(id:any): Observable<any> {
    return this.apiService.get(`Api/Account/Info/${id}`);
  }
  
  getList(params:any): Observable<any> {
    const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.apiService.get(`Api/Account/List?${queryString}`);
  }
  
  updateInfo(account:any): Observable<any> {
    return this.apiService.post(`Api/Account/Info`, account);
  }
  
  deleteInfo(account:any): Observable<any> {
    return this.apiService.delete(`Api/Account/Info`, account);
  }
  
  getHierarchy(id:any): Observable<any> {
    return this.apiService.get(`Api/Account/Hierarchy/${id}`);
  }

  getReferal(id:any): Observable<any> {
    return this.apiService.get(`Api/Account/Referal/${id}`);
  }

  getRole(id:any): Observable<any> {
    return this.apiService.get(`Api/Account/Role/${id}`);
  }

  getDashboard(id:any): Observable<any> {
    return this.apiService.get(`Api/Account/Dashboard/${id}`);
  }

  getProfile(id:any): Observable<any> {
    return this.apiService.get(`Api/Account/Profile/${id}`);
  }

  getDirectList(params:any): Observable<any> {
    const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.apiService.get(`Api/Account/Direct/List?${queryString}`);
  }
  
  getHierarchyList(params:any): Observable<any> {
    const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.apiService.get(`Api/Account/Hierarchy/List?${queryString}`);
  }
  
  getIncome(id:any): Observable<any> {
    return this.apiService.get(`Api/Account/Income/${id}`);
  }
  
  getIncomeList(params:any): Observable<any> {
    const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.apiService.get(`Api/Account/Income/List?${queryString}`);
  }

  getIncomeApprovalList(params:any): Observable<any> {
    const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.apiService.get(`Api/Account/Income/Approval/List?${queryString}`);
  }

  getWallet(id:any): Observable<any> {
    return this.apiService.get(`Api/Account/Wallet/${id}`);
  }
  
  getWalletList(params:any): Observable<any> {
    const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.apiService.get(`Api/Account/Wallet/List?${queryString}`);
  }

  forgotPassword(account:any): Observable<any> {
    return this.apiService.post(`Api/Account/ForgotPassword`, account);
  }
  
  register(account:any): Observable<any> {
    return this.apiService.post(`Api/Account/Register`, account);
  }

  registerValidate(account:any): Observable<any> {
    return this.apiService.post(`Api/Account/Validate/REGISTER ACCOUNT`, account);
  }

  getLevelList(params:any): Observable<any> {
    const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.apiService.get(`Api/Account/Level/List?${queryString}`);
  }

  getIncomeWallet(id:any): Observable<any> {
    return this.apiService.get(`Api/Account/IncomeWallet/${id}`);
  }

  getIncomeWalletList(params:any): Observable<any> {
    const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.apiService.get(`Api/Account/IncomeWallet/List?${queryString}`);
  }

  fundWithdrawal(account:any): Observable<any> {
    return this.apiService.post(`Api/Account/Income/FundWithdrawal`, account);
  }

  fundDeduction(account:any): Observable<any> {
    return this.apiService.post(`Api/Account/Income/FundDeduction`, account);
  }

  fundDeposit(account:any): Observable<any> {
    return this.apiService.post(`Api/Account/Income/FundDeposit`, account);
  }

  incomeApproval(account:any): Observable<any> {
    return this.apiService.post(`Api/Account/Income/Approval`, account);
  }

  incomeDeduction(account:any): Observable<any> {
    return this.apiService.post(`Api/Account/Income/Deduction`, account);
  }

  getPackageList(params:any): Observable<any> {
    const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.apiService.get(`Api/Account/Package/List?${queryString}`);
  }  
}
