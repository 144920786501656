import { Component, OnInit, ViewEncapsulation, Injectable, Input } from "@angular/core";
import { User } from "../../../../core/models";

@Injectable()
@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class SideBarComponent implements OnInit { 
  @Input() User: User;
  @Input() IsAdmin: boolean = false; 
  
  constructor(   
  ) {   
  }

  ngOnInit() {
  }

  onClose() {
    
  }
}
