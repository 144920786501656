import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { NotFoundComponent } from './modules/page/not-found.component';

const routes: Routes = [   
  {
    path: 'home',
    loadChildren: './modules/home/home.module#HomeModule'
  },  
  {
    path: 'legal',
    loadChildren: './modules/legal/legal.module#LegalModule'
  }, 
  {
    path: 'pages',
    loadChildren: './modules/page/page.module#PageModule'
  },
  {
    path: 'shop',
    loadChildren: './modules/shop/shop.module#ShopModule'
  }, 
  {
    path: 'account',
    loadChildren: './modules/account/account.module#AccountModule'
  }, 
  {
    path: 'admin',
    loadChildren: './modules/admin/admin.module#AdminModule'
  }, 
  {
    path: 'report',
    loadChildren: './modules/report/report.module#ReportModule'
  }, 
  {
    path: 'auth',
    loadChildren: './modules/auth/auth.module#AuthModule'
  }, 
  {
    path: 'register',
    loadChildren: './modules/auth/auth.module#AuthModule'
  },
  {
    path: '',
    loadChildren: './modules/home/home.module#HomeModule'
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    useHash: true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
