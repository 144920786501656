import { Injectable } from '@angular/core';

declare let fbq: Function;
@Injectable({
  providedIn: 'root'
})
export class FacebookPixelService {
  constructor() { }

  public eventEmitter(
    eventName: string,
    eventValue: any
  ) {
    fbq('track', eventName, eventValue);
  }
}