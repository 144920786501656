import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-product-quickview-info",
  templateUrl: "./quickview-product-info.component.html",
  styleUrls: ["./quickview-product-info.component.css"]
})
export class ProductQuickViewInfoSingleComponent implements OnInit {
  @Input() Category: any;
  @Input() Tags: string[];
  @Input() SKU: string;
  @Output() OnViewCategory = new EventEmitter();
  @Output() OnViewTags = new EventEmitter();
  
  constructor() {
  }

  ngOnInit() {
  }

  onSearchCategory(item:any) {
    this.OnViewCategory.emit(item);
  }

  onSearchTags(item:any) {
    this.OnViewTags.emit(item);
  }
}
