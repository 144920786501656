import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { LookUpService } from 'src/app/core/services/lookup.service';
import { PageService, HtmlService, ShopService, StoreService, UserService, AccountService } from "../../core/services";
import { LayoutComponent } from "../../shared/components/layout/layout.component";

@Component({
  selector: 'app-registration-auth-page',
  templateUrl: './registration.component.html',
  styleUrls: ["./registration.component.css"]
})
export class RegistrationAuthComponent implements OnInit {
  PageTitle: string = "Registration";
  Country: any[];
  Account:any = {
    ID: '',
    Name: '',
    RegID: ''
  };

  Model: any = {
    ReferalID: '',
    SponsorName: '',
    FirstName: '',
    LastName: '',
    CountryCode: '',
    Country: '',
    Phone: '',
    Email: '',
    SocialSecurityNo: '',
    Password: '',
    RePassword: '',
    IsTermAndCondition: true,
    IsErrorReferalID: false,
    IsErrorFirstName: false,
    IsErrorLastName: false,
    IsErrorPhone: false,
    IsErrorEmail: false,
    IsErrorSocialSecurityNo: false,
    IsErrorPassword: false,
    IsErrorSubmit: false,
    IsSuccessSubmit: false,
    ServerMessage : ''
  }

  Step:number = 1;

  constructor(
    private _htmlService: HtmlService,
    private _lookUpService: LookUpService,
    private _accountService: AccountService,
    private _userService: UserService,
    private _layout: LayoutComponent,
    private _router: Router,
    private _activeRouter: ActivatedRoute
  ) {
    this._activeRouter.queryParams.subscribe(params => {
      this.Model.ReferalID = (params.ID || '');
      this._layout.IsLoaderShow = true;
      this._layout.IsPageShow = true;

      this._htmlService.UpdateMetaTags({ title: this.PageTitle });
      this._htmlService.UpdateAnalyticsEvent("view_item", "page", this.PageTitle);
      this.GetCountry();
      setTimeout(() => this._htmlService.MainInit(), 100);
      if(this.Model.ReferalID != '') this.onReferalIDChange();
    });
  }

  ngOnInit() {
    this._layout.IsLandingPage = false;
    this._layout.IsBlankPage = false;
  }

  GetInformation() {
    this._accountService.getInfo(this.Account.ID).subscribe((data: any[]) => {
      this.Step = this.Step + 1;
      this.Account = data[0];
    });
  }

  GetCountry() {
    this._lookUpService.getCountry().subscribe((data: any) => {
      this.Country = data;
      this._layout.IsLoaderShow = false;
      this.onCountryChange((data[0]._id || ''));
    });
  }

  IsEmail(item) : boolean {
    return this._htmlService.IsEmail(item);
  }

  onCountryChange(item: any) {
    this.Country.filter(x => x._id == item).forEach(e => {
      this.Model.Country = e._id;
      this.Model.CountryCode = e.Code;
    });
  }

  onReferalIDChange() {
    this.Model.SponsorName = '';
    this._accountService.getReferal(this.Model.ReferalID).subscribe((data: any[]) => {
      this.Model.IsErrorReferalID = false;
      if (data.length == 0) this.Model.IsErrorReferalID = true;
      else this.Model.SponsorName = (data[0].Name || '');
    },
      (error: any) => {    
        this.Model.IsErrorReferalID = true;
      });
  }

  onValidate() {   
    if (!this.IsEmail(this.Model.Email) || this.Model.Email == undefined) this.Model.IsErrorEmail = true;
    if (this.Model.ReferalID == '' || this.Model.ReferalID == undefined) this.Model.IsErrorReferalID = true;
    if (this.Model.SponsorName == '' || this.Model.SponsorName == undefined) this.Model.IsErrorReferalID = true;
    if (this.Model.FirstName == '' || this.Model.FirstName == undefined) this.Model.IsErrorFirstName = true;  
    if (this.Model.LastName == '' || this.Model.LastName == undefined) this.Model.IsErrorLastName = true;  
    if (this.Model.Phone == '' || this.Model.Phone == undefined) this.Model.IsErrorPhone = true;  
    if (this.Model.SocialSecurityNo == '' || this.Model.SocialSecurityNo == undefined) this.Model.IsErrorSocialSecurityNo = true;  
    if (this.Model.Password == '' || this.Model.Password == undefined) this.Model.IsErrorPassword = true;  
    if (this.Model.RePassword == '' || this.Model.RePassword == undefined) this.Model.IsErrorPassword = true;  
    if (this.Model.Password != this.Model.RePassword) this.Model.IsErrorPassword = true;  

    if (this.Model.IsErrorReferalID || this.Model.IsErrorFirstName || this.Model.IsErrorLastName || this.Model.IsErrorPhone || 
      this.Model.IsErrorEmail || this.Model.IsErrorSocialSecurityNo || this.Model.IsErrorPassword || !this.Model.IsTermAndCondition) return;

    let dto = {
      ID : this._htmlService.GenerateGUID(),
      UserID : this._htmlService.GenerateGUID(),
      ReferalID: this.Model.ReferalID,
      FirstName: this.Model.FirstName,
      LastName: this.Model.LastName     
    };

    this.Model.IsErrorSubmit = false;
    this.Model.IsSuccessSubmit = false;
    this._accountService.registerValidate(dto).subscribe((data: any) => {
      this.Step = this.Step + 1;
      window.scroll(0, 0);
    },
      (error: any) => {
        this.Model.IsErrorSubmit = true;
        this.Model.ServerMessage = `We cannot process your request. ${error.message}`;
        setTimeout(() => this.Model.IsErrorSubmit = false, 30000);
      });
  }

  onSubmit() {   
    let dto = {
      ID : this._htmlService.GenerateGUID(),
      UserID : this._htmlService.GenerateGUID(),
      ReferalID: this.Model.ReferalID,
      FirstName: this.Model.FirstName,
      LastName: this.Model.LastName,
      Email: this.Model.Email,
      Phone: this.Model.Phone,
      Country: this.Model.Country,
      SocialSecurityNo: this.Model.SocialSecurityNo,
      Password: this.Model.Password,
      IsTermAndCondition: this.Model.IsTermAndCondition,
    };

    this.Model.IsErrorSubmit = false;
    this.Model.IsSuccessSubmit = false;
    this._accountService.register(dto).subscribe((data: any) => {     
      this.Model.IsSuccessSubmit = true;
      this.Account.ID = data;
      window.scroll(0, 0);     
      
      this._userService.logOut();
      this._userService.purgeAuthOnly();
      this.GetInformation();
    },
      (error: any) => {
        this.Model.IsErrorSubmit = true;
        this.Model.ServerMessage = `We cannot process your request. ${error.message}`;
        setTimeout(() => this.Model.IsErrorSubmit = false, 30000);
      });
  }
}
