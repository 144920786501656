import { Component, OnInit, Input} from "@angular/core";
import { User } from "../../../../core/models";

@Component({
  selector: "app-menu-icon",
  templateUrl: "./menu-icon.component.html",
  styleUrls: ["./menu-icon.component.css"]
})
export class MenuIconComponent implements OnInit {  
  @Input() IsAuthenticated: boolean = false;
  @Input() Model: User;
  @Input() WishCount: number;
  @Input() CartCount: number;
  

  constructor(
  ) {
  }

  ngOnInit() {   
  }
}
