import { ViewAccountComponent } from './view-account.component';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { RouterModule } from '@angular/router';

import { AuthComponent } from './auth.component';
import { AuthRoutingModule } from './auth-routing.module';
import { SharedModule } from './../../shared/shared.module';
import { AuthResolver } from './auth-resolver.service';
import { LostPasswordAuthComponent } from "./lost-passwod.component";
import { RegistrationAuthComponent } from "./registration.component";
import { LogoutComponent } from "./logout.component";
import { StepRegistrationComponent } from "./shared/step-registration.component";
import { RegistrationInfoAuthComponent } from "./registration-info.component";

@NgModule({
  imports: [
    SharedModule,
    AuthRoutingModule
  ],
  declarations: [
    AuthComponent,
    LogoutComponent,
    LostPasswordAuthComponent,
    RegistrationAuthComponent,
    RegistrationInfoAuthComponent,
    StepRegistrationComponent,
    ViewAccountComponent
  ],
  providers: [AuthResolver],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AuthModule {}
