import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { HtmlService, UserService } from "../../core/services";
import { LayoutComponent } from "../../shared/components/layout/layout.component";

@Component({
  selector: 'app-logout-page',
  templateUrl: './logout.component.html',
  styleUrls: ["./logout.component.css"]
})
export class LogoutComponent implements OnInit {
  PageTitle : string = "logout";  

  constructor(
    private _htmlService: HtmlService,
    private _userService: UserService,
    private _layout: LayoutComponent,
    private _router: Router,
    private _activeRouter: ActivatedRoute
  ) {
    this._activeRouter.queryParams.subscribe(params => {
      this._layout.IsLoaderShow = true;
      this._layout.IsPageShow = true; 

      this._htmlService.UpdateMetaTags({ title: this.PageTitle});
      this._htmlService.UpdateAnalyticsEvent("view_item", "page", this.PageTitle);     
      this.LogoutAccount(); 
      this._layout.IsLoaderShow = false;
    });
  }

  ngOnInit() {
    this._layout.IsLandingPage = false;
    this._layout.IsBlankPage = false;
  }

  LogoutAccount() : void  {
    if (this._layout.Reference && this._layout.Reference != '') {
      this._router.navigate(["/auth/view-account"], { queryParams: { ID: this._layout.Reference, A: true } });
    }
    else {
      this._userService.logOut();
      this._userService.purgeAuth();
    }    
  }
}
