import { Component, OnInit, Input, Output, EventEmitter} from "@angular/core";
import { interval } from "rxjs";
import { LayoutComponent } from "../layout/layout.component";
import { HtmlService } from "../../../core/services";

@Component({
  selector: "app-overlay-menu",
  templateUrl: "./menu-overlay.component.html",
  styleUrls: ["./menu-overlay.component.css"]
})
export class OverlayMenuComponent implements OnInit {  
  @Output() OnViewItem = new EventEmitter();

  constructor(
    private _htmlService: HtmlService
  ) {
  }

  ngOnInit() { 
  }

  onClose() {
    setTimeout(() => this._htmlService.MainInit(), 100);
    setTimeout(() => this._htmlService.OverlayRemove(), 100);    
  }

  onViewItem(item) {
    this.OnViewItem.emit(item);
  }
}
