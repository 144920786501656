import { Component, OnInit, Input} from "@angular/core";
import { User } from "../../../../core/models";
import { HtmlService } from "../../../../core/services/html.service";

@Component({
  selector: "app-menu-burger",
  templateUrl: "./menu-burger.component.html",
  styleUrls: ["./menu-burger.component.css"]
})
export class MenuBurgerComponent implements OnInit {  
  @Input() IsAuthenticated: boolean = false;
  @Input() IsAdmin: boolean = false;
  @Input() Model: User;  

  constructor(
    private _htmlService: HtmlService
  ) {
  }

  ngOnInit() {   
    this._htmlService.DlMenuInit();
  }

  onClick() {
    this._htmlService.DlMenuInit();
  }

  onClose() {
    this._htmlService.OverlayRemove();
  }
}
