import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class ProductService {
  constructor(private apiService: ApiService) { }
 
  getInfo(id:any): Observable<any> {
    return this.apiService.get(`Api/Product/Info/${id}`);
  }
  
  getList(params:any): Observable<any> {
    const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.apiService.get(`Api/Product/List?${queryString}`);
  }
  
  updateInfo(product:any): Observable<any> {
    return this.apiService.post(`Api/Product/Info`, product);
  }
  
  deleteInfo(product:any): Observable<any> {
    return this.apiService.delete(`Api/Product/Info`, product);
  }

  getSimilarInfo(id:any) {
    return this.apiService.get(`Api/Product/Similar/${id}`);
  } 

  getPackageInfo(id:any): Observable<any> {
    return this.apiService.get(`Api/Product/Package/Info/${id}`);
  }
  
  getPackageList(params:any): Observable<any> {
    const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.apiService.get(`Api/Product/Package/List?${queryString}`);
  }
  
  updatePackageInfo(pack:any): Observable<any> {
    return this.apiService.post(`Api/Product/Package/Info`, pack);
  }
  
  deletePackageInfo(pack:any): Observable<any> {
    return this.apiService.delete(`Api/Product/Package/Info`, pack);
  }
  
  getCategotyInfo() {
    return this.apiService.get(`Api/Product/Store/Category`);
  } 

  getFilterInfo() {
    return this.apiService.get(`Api/Product/Store/Filter`);
  } 

  getCategoryInfo(id:any): Observable<any> {
    return this.apiService.get(`Api/Product/Category/Info/${id}`);
  }
  
  getCategoryList(params:any): Observable<any> {
    const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.apiService.get(`Api/Product/Category/List?${queryString}`);
  }

  updateCategotyInfo(product:any): Observable<any> {
    return this.apiService.post(`Api/Product/Category`, product);
  }

  deleteCategotyInfo(category:any): Observable<any> {
    return this.apiService.delete(`Api/Product/Category`, category);
  }
}
