import {
  Directive,
  HostBinding,
  HostListener,
  ElementRef,
  OnInit
} from "@angular/core";

@Directive({
  selector: "[appDropDown]",
  exportAs: "appDropDown"
})
export class DropdownDirective implements OnInit {
  @HostBinding("class.open") isOpen: boolean;

  constructor(private elemRef: ElementRef) {}

  ngOnInit(): void {
    this.isOpen = false;
  }

  @HostListener("document:click", ["$event"])
  @HostListener("document:touchstart", ["$event"])
  toggle(event) {
    if (this.elemRef.nativeElement.contains(event.target)) {
      this.isOpen = !this.isOpen;
    } else {
      this.isOpen = false;
    }
  }
}
