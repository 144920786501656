import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { interval } from "rxjs";
import { Product, Size } from "../../../core/models";

@Component({
  selector: "app-product-quickview",
  templateUrl: "./quickview-product.component.html",
  styleUrls: ["./quickview-product.component.css"]
})
export class ProductQuickViewSingleComponent implements OnInit {
  @Input() Model: Product;
  @Output() OnAddToCart = new EventEmitter();
  @Output() OnSearchItem = new EventEmitter();

  CartText:string = "add to Cart";
  CurrentPrice:number = 0;
  CurrentImage:string = "";
  IsAddCart: boolean = false;
  SizeList: Size[] = [];
  ImageList: any[] = [];

  constructor() {
  }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.Model != undefined) {
      if (this.Model.Variation != undefined) this.Model.Variation.forEach((e, i) => { e.Active = (i == 0); });
      if (this.Model.Variation != undefined) this.onColorClick(this.Model.Variation[0]);
      if (this.Model.Quantity == undefined) this.Model.Quantity= 1;
    
      this.CurrentPrice = this.Model.Price;
      this.onImageChanges();
    }   
  }

  onImageChanges() {
    this.ImageList = [];
    this.Model.Image.forEach((item, i) => {
      this.ImageList.push({ id: i, Image: item, Active : (i == 0)} );
    });
  }

  onNextImage() {
    let index = 0;
    this.ImageList.filter(x => x.Active).forEach(e => { index = (e._id + 1) });
    this.ImageList.forEach(e => { e.Active = false });
    this.ImageList.filter(x => x._id == ((index > this.ImageList.length) ? 1 : index)).forEach(e => { e.Active = true });
  }

  onPrevImage() {
    let index = 0;
    this.ImageList.filter(x => x.Active).forEach(e => { index = (e._id - 1) });
    this.ImageList.forEach(e => { e.Active = false });
    this.ImageList.filter(x => x._id == ((index < 1) ? this.ImageList.length : index)).forEach(e => { e.Active = true });
  }

  onDotImage(item: any) {
    this.ImageList.forEach(e => { e.Active = false });
    this.ImageList.filter(x => x._id == item._id).forEach(e => { e.Active = true });
  }

  onColorImage(item: any) {
    this.ImageList.forEach(e => { e.Active = false });
    this.ImageList.filter(x => x.Image == item.Image).forEach(e => { e.Active = true });
    this.CurrentImage = item.Image;
  }

  onAddToCart() {
    let item: any = {};
    item._id = this.Model._id;
    item.SKU = this.Model.SKU;
    item.Name = this.Model.Name;
    item.Currency = this.Model.Currency;
    item.Price = this.CurrentPrice;
    item.ShortDescription = this.Model.ShortDescription;
    item.FullDescription = this.Model.FullDescription;    
    item.Image = this.Model.Image;
    item.Quantity= this.Model.Quantity;   
    item.ThumbImage = [this.CurrentImage];
    item.ShippingFee = this.Model.ShippingFee;
    
    if(this.Model.Variation != undefined) {
      item.Variation = this.Model.Variation.filter(x => x.Active);
      this.SizeList.filter(x => x.Active).forEach(e => item.SelectedProductSize = e.Name);
      this.Model.Variation.filter(x => x.Active).forEach(e => item.SelectedProductColor = e.Color);
    }  

    this.IsAddCart = true;
    this.CartText = "added!"
    setTimeout(() => { this.IsAddCart = false;}, 500);
    setTimeout(() => { this.CartText = "add to Cart"}, 1500);
    this.OnAddToCart.emit(item);
  }

  onSearchCategory(item) {
    this.OnSearchItem.emit({ type: 'category', search: item });
  }

  onSearchTags(item) {
    this.OnSearchItem.emit({ type: 'tag', search: item });
  }

  onSizeClick(item) {
    this.SizeList.filter(y => y.Name != item.Name).forEach(x => x.Active = false);
    this.CurrentPrice = item.Price;
    item.Active = true;
  }

  onColorClick(item) {
    let current = this.SizeList.filter(x => x.Name);
    if(current.length != 0) item.Size.forEach((x, i) => x.Active = (x.Name == current[0].Name));
    else item.Size.forEach((x, i) => x.Active = (i == 0));

    this.onColorImage(item);
    this.Model.Variation.forEach(x => x.Active = false);
    this.SizeList = item.Size;
    item.Active = true;
  }
}
