import { ErrorHandler, Injectable, Injector } from "@angular/core";

@Injectable()
export class GlobalErrorInterceptor implements ErrorHandler {
  constructor(
  ) { }

  handleError(error: any): void {
    console.log(error);
  }
}
