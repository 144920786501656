import { Component, OnInit, ViewEncapsulation, HostListener, Inject, Input } from "@angular/core";

@Component({
  selector: "app-breadcrumb-title",
  templateUrl: "./breadcrumb-title.component.html",
  styleUrls: ["./breadcrumb-title.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class BreadcrumbTitleComponent implements OnInit { 
  @Input() PageTitle: string = "";
  @Input() Module: string = "";
  @Input() Image: string = "";

  ImagePath:string;

  ngOnInit() {      
  }

  ngOnChanges() {
    this.ImagePath = `url('${this.Image}'), url('assets/images/breadcrumb-bg/background-heading-page.png')`;
  }
}
