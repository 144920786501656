import { Component, OnInit, Input } from "@angular/core";
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: "app-iframe-container",
  templateUrl: "./iframe-container.component.html",
  styleUrls: ["./iframe-container.component.css"]
})
export class IframeContainerComponent implements OnInit {
  @Input() Link: string;
  @Input() Height: number;
  MapsURL:string;
  MapsHeight:number;

  constructor(
    public sanitizer: DomSanitizer
  ) {
  }

  ngOnInit() {   
  }

  ngOnChanges() {
    if(this.Link) this.MapsURL = this.Link;
    this.MapsHeight = this.Height || 200;
  }
}
