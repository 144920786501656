import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class LookUpService {
  constructor(private apiService: ApiService) { }
 
  getCity(): Observable<any> {
    return this.apiService.get(`Api/LookUp/City/List`);
  }

  getCityInfo(id:string): Observable<any> {
    return this.apiService.get(`Api/LookUp/City/${id}`);
  }
  
  updateCity(data:any): Observable<any> {
    return this.apiService.post(`Api/LookUp/City`, data);
  }
  
  deleteCity(data:any): Observable<any> {
    return this.apiService.delete(`Api/LookUp/City`, data);
  }
  
  getColor(): Observable<any> {
    return this.apiService.get(`Api/LookUp/Color/List`);
  }
  
  updateColor(data:any): Observable<any> {
    return this.apiService.post(`Api/LookUp/Color`, data);
  }
  
  deleteColor(data:any): Observable<any> {
    return this.apiService.delete(`Api/LookUp/Color`, data);
  }
  
  getCountry(): Observable<any> {
    return this.apiService.get(`Api/LookUp/Country/List`);
  }
    
  updateCountry(data:any): Observable<any> {
    return this.apiService.post(`Api/LookUp/Country`, data);
  }
    
  deleteCountry(data:any): Observable<any> {
    return this.apiService.delete(`Api/LookUp/Country`, data);
  }
  
  getPosition(): Observable<any> {
    return this.apiService.get(`Api/LookUp/Position`);
  }
  
  updatePosition(data:any): Observable<any> {
    return this.apiService.post(`Api/LookUp/Position`, data);
  }
  
  deletePosition(data:any): Observable<any> {
    return this.apiService.delete(`Api/LookUp/Position`, data);
  }
  
  getRole(): Observable<any> {
    return this.apiService.get(`Api/LookUp/Role`);
  }
  
  updateRole(data:any): Observable<any> {
    return this.apiService.post(`Api/LookUp/Role`, data);
  }
  
  deleteRole(data:any): Observable<any> {
    return this.apiService.delete(`Api/LookUp/Role`, data);
  }
  
  getSize(): Observable<any> {
    return this.apiService.get(`Api/LookUp/Size/List`);
  }
  
  updateSize(data:any): Observable<any> {
    return this.apiService.post(`Api/LookUp/Size`, data);
  }
  
  deleteSize(data:any): Observable<any> {
    return this.apiService.delete(`Api/LookUp/Size`, data);
  }
  
  getState(): Observable<any> {
    return this.apiService.get(`Api/LookUp/State/List`);
  }

  getStateInfo(id:string): Observable<any> {
    return this.apiService.get(`Api/LookUp/State/${id}`);
  }
  
  updateState(data:any): Observable<any> {
    return this.apiService.post(`Api/LookUp/State`, data);
  }
  
  deleteState(data:any): Observable<any> {
    return this.apiService.delete(`Api/LookUp/State`, data);
  }

  getLevel(): Observable<any> {
    return this.apiService.get(`Api/LookUp/Level/List`);
  }

  getProduct(): Observable<any> {
    return this.apiService.get(`Api/LookUp/Product/List`);
  }

  getDateRange(): Observable<any> {
    return this.apiService.get(`Api/LookUp/DateRange`);
  }

  getDashboard(account:any): Observable<any> {
    return this.apiService.get(`Api/LookUp/Dashboard/${account}`);
  }
}
