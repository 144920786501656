import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { SeoService } from './seo.service';
import { GoogleAnalyticsService } from './google-analytics';
import { FacebookPixelService } from './facebook-pixel';

declare const OnMainLoad: any;
declare const OnQuickViewLoad: any;
declare const OnPriceRangeLoad: any;
declare const OnProductImageSlide: any;
declare const OnProductImageSlideTo: any;
declare const OnOverlayRemove: any;
declare const OnCartCounter: any;
declare const OnDlMenuInit: any;
declare const OnSidebarCategoryLoad: any;
declare const OnParallax:any;

@Injectable({
  providedIn: "root"
})
export class HtmlService {
  URL: string;

  constructor(
    private _seoService: SeoService,
    private _googleAnalyticsService: GoogleAnalyticsService,
    private _facebookPixelService: FacebookPixelService,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this.URL = `${this._document.location.origin}/#`;
  }

  GetFacebookEvent(item: string): string {
    if (item === 'add_to_cart') return 'AddToCart';
    else if (item === 'add_to_wishlist') return 'AddToWishlist';
    else if (item === 'view_item') return 'ViewContent';
    else if (item === 'generate_lead') return 'Subscribe';
    else if (item === 'purchase') return 'Purchase';
    else if (item === 'search') return 'Search';
    else return item;
  }

  UpdateAnalyticsEvent(eventName: string, eventCategory: string, eventAction: string, eventLabel: string = null, eventValue: number = null): void {
    // this._googleAnalyticsService.eventEmitter(eventName, eventCategory, eventAction, eventLabel, eventValue);
    // this._facebookPixelService.eventEmitter(this.GetFacebookEvent(eventName), {
    //   content_name: eventAction,
    //   content_category: eventCategory,
    //   value: eventValue
    // });
  }

  UpdateGoogleEvent(eventName: string, eventCategory: string, eventAction: string, eventLabel: string = null, eventValue: number = null): void {
    //this._googleAnalyticsService.eventEmitter(eventName, eventCategory, eventAction, eventLabel, eventValue);
  }

  UpdateFacebookEvent(eventName: string, eventValue: any): void {
    //this._facebookPixelService.eventEmitter(eventName, eventValue);
  }

  UpdateMetaTags(config: any): void {
    config.url = `${this.URL}`;
    this._seoService.generateTags(config);
  }

  UpdateMetaTagsPage(page: any): void {
    this._seoService.generateTags({
      title: page.Description,
      description: page.Description,
      image: page.ImagePath,
      slug: page.Url,
      keywords: page.Keywords,
      url: `${this.URL}`
    });
  }

  IsEmail(text:string) : boolean {
    let regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(text);
  }

  GenerateGUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
  
  ReloadPage() {
    this._document.defaultView.location.reload();
  }

  MainInit() {
    OnMainLoad()
  }

  QuickViewInit() {
    OnQuickViewLoad();
  }

  PriceRangeInit() {
    OnPriceRangeLoad();
  }

  ProductImageSlideInit() {
    OnProductImageSlide();
  }

  ProductImageSlideTo(name) {
    OnProductImageSlideTo(name);
  }

  OverlayRemove() {
    OnOverlayRemove();
  }

  CartCounterInit() {
    OnCartCounter()
  }

  SidebarCategoryInit() {
    OnSidebarCategoryLoad()
  }

  DlMenuInit() {
    OnDlMenuInit()
  }

  ParallaxInit() {
    OnParallax();
  }
}