import { AccountService } from './../../core/services/account.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { PageService, HtmlService, ShopService, StoreService, UserService } from "../../core/services";
import { LayoutComponent } from "../../shared/components/layout/layout.component";

@Component({
  selector: 'app-view-account-page',
  templateUrl: './view-account.component.html',
  styleUrls: ["./view-account.component.css"]
})
export class ViewAccountComponent implements OnInit {
  PageTitle : string = "view account";  
  AccountID: string;
  IsAdmin: boolean;

  constructor(
    private _htmlService: HtmlService,
    private _userService: UserService,
    private _accountService: AccountService,
    private _layout: LayoutComponent,
    private _router: Router,
    private _activeRouter: ActivatedRoute
  ) {
    this._activeRouter.queryParams.subscribe(params => {
      this.AccountID = params.ID;
      this.IsAdmin = params.A == "true";
      this._layout.IsLoaderShow = true;
      this._layout.IsPageShow = true; 

      this._htmlService.UpdateMetaTags({ title: this.PageTitle});
      this._htmlService.UpdateAnalyticsEvent("view_item", "page", this.PageTitle); 
      this.ViewAccount();
      this._layout.IsLoaderShow = false;
    });
  }

  ngOnInit() {
    this._layout.IsLandingPage = false;
    this._layout.IsBlankPage = false;
  }

  ViewAccount() : void {
    if (!this.IsAdmin) {
      localStorage['reference'] = this._layout.UserData._id;
      this._layout.Reference = this._layout.UserData._id;
    }
    else {
      localStorage.removeItem('reference');
      this._layout.Reference = undefined;
    }   

    this._accountService.getInfo(this.AccountID).subscribe((data: any[]) => {
      if (data.length != 0) {
        let dto = {
          UserName: data[0].RegID,
          Password: data[0].Type
        };

        this._layout.IsLoaderShow = true;
        this._userService.attemptAuth(dto).subscribe((data: any) => {
          this._layout.UserData = data;    
          this._router.navigate(["/account/dashboard/"]);
          this._layout.IsLoaderShow = false;
        },
        (error: any) => {
          this._layout.IsLoaderShow = false;
        });
      }
    });
  }
}
