import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-submit-message",
  templateUrl: "./submit-message.component.html",
  styleUrls: ["./submit-message.component.css"]
})
export class SubmitMessageComponent implements OnInit {
  @Input() IsError: boolean;
  @Input() IsSuccess: boolean;
  @Input() ErrorMessage: string;
  @Input() SuccessMessage: string;

  ErroMsg: string;
  SuccessMsg: string;

  constructor(
  ) {
  }

  ngOnInit() {
  }

  ngOnChanges() {
    this.ErroMsg = this.ErrorMessage || "We cannot process your request please try again later";
    this.SuccessMsg = this.SuccessMessage || "Thank you. Saved successfully";
  }
}
