import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject, ReplaySubject } from "rxjs";

import { ApiService } from "./api.service";
import { JwtService } from "./jwt.service";
import { User } from "../models";
import { map, distinctUntilChanged } from "rxjs/operators";
import { Router } from "@angular/router";
import { AccountService } from "./account.service";

@Injectable()
export class UserService {
  private currentUserSubject = new BehaviorSubject<User>({} as User);
  public currentUser = this.currentUserSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();

  constructor(
    private apiService: ApiService,
    private accountService: AccountService,
    private http: HttpClient,
    private jwtService: JwtService,
    private router: Router
  ) { }

  populate() {
    if (this.jwtService.getToken()) {
      this.apiService.get(`Api/Token`).subscribe(
        data => this.setAuth(data[0]),
        err => this.purgeAuth()
      );
    } else {
      this.purgeAuth();
    }
  }

  exceptedLocation(): boolean {
    let path = window.location.pathname;
    return (path.includes('login') || path.includes('register') || path.includes('activation'))
  }

  setAuth(user: any) {
    if (user != undefined || user != null) {
      this.accountService.getRole(user._id).subscribe((data: any) => {
        if (data != undefined || data != null) {
          this.jwtService.saveToken(user.Token);
          this.currentUserSubject.next(data[0]);
          this.isAuthenticatedSubject.next(true);
        }
      });
    } else {
      this.purgeAuth();
    }
  }

  purgeAuth() {
    this.jwtService.destroyToken();
    this.currentUserSubject.next({} as User);
    this.isAuthenticatedSubject.next(false);
    this.router.navigateByUrl('/');
  }

  purgeAuthOnly() {
    this.jwtService.destroyToken();
    this.currentUserSubject.next({} as User);
    this.isAuthenticatedSubject.next(false);
  }

  attemptAuth(credentials): Observable<User> {
    return this.apiService.postData(`Api/Token`, credentials).pipe(
      map(data => {
        this.setAuth(data);
        return data;
      })
    );
  }

  getCurrentUser(): User {
    return this.currentUserSubject.value;
  }

  getToken(): Observable<any> {
    return this.apiService.get(`Api/Token`);
  }

  logOut(): Observable<any> {
    return this.apiService.post(`Api/Token/LogOut`, "");
  }

  update(user): Observable<User> {
    return this.apiService.put("/user", { user }).pipe(
      map(data => {
        this.currentUserSubject.next(data.user);
        return data.user;
      })
    );
  }
}
