import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Product } from "../../../core/models";
import { LayoutComponent } from "../layout/layout.component";
import { Router } from "@angular/router";
import { HtmlService } from "../../../core/services";

@Component({
  selector: "app-overlay-wish",
  templateUrl: "./wish-overlay.component.html",
  styleUrls: ["./wish-overlay.component.css"]
})
export class OverlayWishComponent implements OnInit {
  @Input() Model: Product[] = [];

  constructor(
    private _htmlService: HtmlService,
    private _layout: LayoutComponent,
    private _router: Router,
  ) {
  }

  ngOnInit() {
  }

  onViewItem(item) {
    this._htmlService.OverlayRemove();
    this._router.navigateByUrl('/shop/product/' + item._id);
  }

  onViewWish() {
    this._htmlService.OverlayRemove();
    this._router.navigateByUrl('/shop/wishlist');
  }

  onRemoveItem(item) {
    this._layout.onRemoveWishList(item);
  }
}
