import { Component, OnInit, Input, Output, EventEmitter} from "@angular/core";
import { interval } from "rxjs";
import { Product } from "../../../core/models";

@Component({
  selector: "app-package-widget-single",
  templateUrl: "./widget-single-package.component.html",
  styleUrls: ["./widget-single-package.component.css"]
})
export class PackageWidgetSingleComponent implements OnInit {  
  @Input() Model: Product;  

  constructor() {
  }

  ngOnInit() { 
  }
}
