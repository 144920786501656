export class DTO {
    ShopFilter = {       
        Color: [],
        Size: [],
        Sorting: []
    }
}

export interface PaginationDTO {
    ID?: string;
    From?: string;
    To?: string;
    Row?: number;
    Count?: number;
    Limit?: number;
    Offset: number;
    Order?: string;
    Sort?: string;
    Search?: string;
  }