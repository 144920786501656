import { Component, OnInit, Input, EventEmitter, Output} from "@angular/core";
import { User } from "../../../../core/models";

@Component({
  selector: "app-sidebar-account",
  templateUrl: "./sidebar-account.component.html",
  styleUrls: ["./sidebar-account.component.css"]
})
export class SideBarAccountComponent implements OnInit {  
  @Input() IsAuthenticated: boolean = false;
  @Input() Model: User;
  @Output() OnClose = new EventEmitter();

  constructor(
  ) {
  }

  ngOnInit() {   
  }

  onClose() {
    this.OnClose.emit();
  }
}
