import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class StoreService {
  constructor(private apiService: ApiService) { }
 
  getInfo(): Observable<any> {
    return this.apiService.get(`Api/Store/Info`);
  }
  
  getInfoByID(id:any): Observable<any> {
    return this.apiService.get(`Api/Store/Info/${id}`);
  }
  
  getList(params:any): Observable<any> {
    const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.apiService.get(`Api/Store/Info/List?${queryString}`);
  }
  
  updateInfo(store:any): Observable<any> {
    return this.apiService.post(`Api/Store/Info`, store);
  }
  
  deleteInfo(store:any): Observable<any> {
    return this.apiService.delete(`Api/Store/Info`, store);
  }
  
  contactUs(store:any): Observable<any> {
    return this.apiService.post(`Api/Store/ContactUs`, store);
  }
  
  newsLetter(store:any): Observable<any> {
    return this.apiService.post(`Api/Store/NewsLetter`, store);
  }

  getAboutInfo(): Observable<any> {
    return this.apiService.get(`Api/Store/About/Info`);
  }

  getContactUsInfo(): Observable<any> {
    return this.apiService.get(`Api/Store/ContactUs/Info`);
  }

  getNewsLetterList(params:any): Observable<any> {
    const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.apiService.get(`Api/Store/NewsLetter/List?${queryString}`);
  }

  getMessageList(params:any): Observable<any> {
    const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.apiService.get(`Api/Store/Message/List?${queryString}`);
  }

  getShippingChargeAmount(amount:number): Observable<any> {
    return this.apiService.get(`Api/Store/ShippingCharge/${amount}`);
  }

  getShippingChargeList(params:any): Observable<any> {
    const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.apiService.get(`Api/Store/ShippingCharge/List?${queryString}`);
  }

  updateShippingCharge(store:any): Observable<any> {
    return this.apiService.post(`Api/Store/ShippingCharge`, store);
  }

  deleteShippingCharge(store:any): Observable<any> {
    return this.apiService.delete(`Api/Store/ShippingCharge`, store);
  }
}
