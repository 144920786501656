import { Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "app-btn-dark",
  templateUrl: "./btn-dark.component.html",
  styleUrls: ["./btn-dark.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class ButtonDarkComponent { 
  @Input() Text : string;
  @Output() OnClick = new EventEmitter();
  
  onClick() {
    this.OnClick.emit();
  }
}
