import { Component, OnInit, Input, Output, EventEmitter} from "@angular/core";
import { Router } from "@angular/router";
import { HtmlService } from "../../../core/services";

@Component({
  selector: "app-overlay-search",
  templateUrl: "./search-overlay.component.html",
  styleUrls: ["./search-overlay.component.css"]
})
export class OverlaySearchComponent implements OnInit {  
  searchText:string = "";

  constructor(
    private _htmlService: HtmlService,
    private _router: Router,
  ) {
  }

  ngOnInit() { 
  }

  onSearchItem() {
  setTimeout(() => this._htmlService.OverlayRemove(), 100);
   this._router.navigateByUrl(`/shop?q=${this.searchText}`);
   this.searchText = "";
  }
}
