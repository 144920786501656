import { Component, OnInit, ViewEncapsulation, HostListener, Inject, Input, ViewChild, Output, EventEmitter } from "@angular/core";
import { ModalManager } from 'ngb-modal';

@Component({
  selector: "app-modal-image-upload",
  templateUrl: "./modal-image-upload.component.html",
  styleUrls: ["./modal-image-upload.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class ModalImageUploadComponent implements OnInit {
  @ViewChild("myModal", { static: false }) myModal;
  @Input() Title: string = "";
  @Output() OnSubmit = new EventEmitter();

  private modalRef;
  public FileName: any;
  public FileData: any;

  constructor(
    private _modalService: ModalManager,
  ) {
  }

  ngOnInit() {
  }

  openModal() {
    this.modalRef = this._modalService.open(this.myModal, {
      size: "lg",
      modalClass: 'modal-image-upload',
      hideCloseButton: false,
      centered: true,
      backdrop: true,
      animation: true,
      keyboard: false,
      closeOnOutsideClick: true,
      backdropClass: "modal-backdrop"
    });
  }

  onFileChange(event): void {
    if (event.target.files && event.target.files.length) {
      Array.from(event.target.files).forEach((file: File) => {
        if (file.size > 2097152) {
          return;
        };

        let fileReader = new FileReader();
        fileReader.onloadend = (e) => {
          this.FileData = fileReader.result;
        }
        fileReader.readAsDataURL(file);
        this.FileName = file.name;
      });
    }
  }

  onCancel() {
    this.FileData = "/assets/images/item-not-found.jpg";
    this._modalService.close(this.modalRef);
  }

  onSubmit() {
    this.OnSubmit.emit({ Name : this.FileName, Data : this.FileData });
  } 
}
