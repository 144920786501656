import { Component, OnInit, Input, EventEmitter, Output} from "@angular/core";
import { User } from "../../../../core/models";

@Component({
  selector: "app-sidebar-admin",
  templateUrl: "./sidebar-admin.component.html",
  styleUrls: ["./sidebar-admin.component.css"]
})
export class SideBarAdminComponent implements OnInit {  
  @Input() IsAuthenticated: boolean = false;
  @Input() Model: User;
  @Output() OnClose = new EventEmitter();

  constructor(
  ) {
  }

  ngOnInit() {   
  }

  onClose() {
    this.OnClose.emit();
  }
}
