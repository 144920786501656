import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, throwError } from "rxjs";

import { catchError } from "rxjs/operators";
import { environment } from "../../../environments/environment.prod";

@Injectable()
export class ApiService {
  constructor(private http: HttpClient) { }

  private formatErrors(error: any) {
    return throwError(error.error);
  }

  getEndpoint(): string {
    return environment.api_url;
  }

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http
      .get(`${this.getEndpoint()}${path}`, { params })
      .pipe(catchError(this.formatErrors));
  }

  put(path: string, body: Object = {}): Observable<any> {
    return this.http
      .put(`${this.getEndpoint()}${path}`, JSON.stringify(body))
      .pipe(catchError(this.formatErrors));
  }

  post(path: string, body: Object = {}): Observable<any> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http
      .post(`${this.getEndpoint()}${path}`, body, { headers: headers })
      .pipe(catchError(this.formatErrors));
  }

  postData(path: string, body: any): Observable<any> {
    return this.http
      .post(`${this.getEndpoint()}${path}`, body);
  }

  delete(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http
      .delete(`${this.getEndpoint()}${path}`, { params })
      .pipe(catchError(this.formatErrors));
  }
}
