import { Component, OnInit, Input, Output, EventEmitter} from "@angular/core";
import { Store, User } from "../../../../core/models";
import { HtmlService } from "../../../../core/services";

@Component({
  selector: "app-menu-container",
  templateUrl: "./menu-container.component.html",
  styleUrls: ["./menu-container.component.css"]
})
export class MenuContainerComponent implements OnInit {  
  @Input() IsAuthenticated: boolean = false;
  @Input() IsAdmin: boolean = false;
  @Input() IsMobile: boolean = false;
  @Input() IsLandingPage:boolean = true;
  @Input() Model: Store;
  @Input() User: User;
  @Input() WishCount:number = 0;
  @Input() CartCount:number = 0;
  @Output() OnViewItem = new EventEmitter();

  constructor(
    private _htmlService: HtmlService
  ) {
  }

  ngOnInit() {   
    this._htmlService.DlMenuInit();
  }

  onViewItem(item) {
    this.OnViewItem.emit(item);
  }
}
