import { Injectable, Inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '../../../environments/environment.prod';

@Injectable({
  providedIn: "root"
})
export class SeoService {
  constructor(
    private _meta: Meta,
    private _titleService: Title
  ) {}

  generateTags(config) {        
    config.Image = (config.Image == undefined || config.Image == '' ? environment.image : config.Image);
    config.keywords = (config.keywords == undefined || config.keywords == '' ? environment.keywords : config.keywords);
    config.description = (config.description == undefined || config.description == '' ? config.title : config.description);  
    config.Slug = (config.Slug == undefined || config.Slug == '' ? '' : config.Slug);   
    config.title = `${config.title} | ${environment.title}`;
    config.url = config.url.replace('#', '')

    this._titleService.setTitle(config.title);
    this._meta.updateTag({ name: 'description', content: config.description });
    this._meta.updateTag({ name: 'keywords', content: config.keywords });

    this._meta.updateTag({ property: 'twitter:card', content: window['twitter:card'] });
    this._meta.updateTag({ property: 'twitter:site', content: window['twitter:site'] });
    this._meta.updateTag({ property: 'twitter:title', content: config.title });
    this._meta.updateTag({ property: 'twitter:description', content: config.description });
    this._meta.updateTag({ property: 'twitter:image', content: `${config.url}${config.Image}`});
    this._meta.updateTag({ property: 'twitter:url', content: `${config.url}${config.Slug}` });

    this._meta.updateTag({ property: 'og:type', content: window['og:type'] });
    this._meta.updateTag({ property: 'og:site_name', content:  window['og:site_name'] });
    this._meta.updateTag({ property: 'og:title', content: config.title });
    this._meta.updateTag({ property: 'og:description', content: config.description });
    this._meta.updateTag({ property: 'og:image', content: `${config.url}${config.Image}`});
    this._meta.updateTag({ property: 'og:url', content: `${config.url}${config.Slug}` });
  }
}