import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { ModalModule } from 'ngb-modal';
import { NgxOrgChartModule } from 'ngx-org-chart';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { AutoFocusDirective, DropdownDirective, ShowAuthedDirective, SortTableDirective } from "./directives";
import { SafeUrlPipe } from "./pipes";

import {
  OverlayCartComponent,
  OverlayWishComponent,
  OverlayMenuComponent,
  OverlaySearchComponent,
  OverlayIconBarComponent,
  OverlayMobileSiteMenuComponent,
} from "./components/overlay";

import {
  ProductShopSingleComponent,
  PackageShopSingleComponent,
  ProductWidgetSingleComponent,
  ProductQuickViewSingleComponent,
  ProductQuickViewInfoSingleComponent,
  ProductBigImageComponent,
  ProductQuickViewVariantSingleComponent,
  PackageWidgetSingleComponent
} from "./components/product";

import {
  LayoutLoaderComponent,
  LayoutOverlayContentComponent,
  SideBarComponent,
  SideBarAccountComponent,
  SideBarAdminComponent,
  MenuContainerComponent,
  MenuNavbarComponent,
  MenuAccountComponent,
  MenuAdminComponent,
  MenuIconComponent,
  MenuBurgerComponent,
  FooterInfoComponent,
  SideBarOverlayComponent
} from "./components/layout";

import {
  BreadcrumbTitleComponent,
  CardCounterComponent,
  ContactUsContainerComponent,
  IframeContainerComponent,
  ImageHolderComponent,
  LoadAllComponent,
  LoadMoreComponent,
  ModalImageUploadComponent,
  SectionTitleComponent,
  SubmitMessageComponent,
  TablePageCountComponent,
  TablePaginationComponent
} from './components/common';

import { ContentDetailsFormComponent, ContentNoBorderMarginComponent, ContentPageWrapperComponent } from './components/container';

import { ButtonBlockDangerComponent, ButtonBlockDarkComponent, ButtonDangerComponent, ButtonDarkComponent } from './components/button';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AngularEditorModule,
    ModalModule,
    NgxOrgChartModule,
    RouterModule
  ],
  declarations: [
    LayoutOverlayContentComponent,
    LayoutLoaderComponent,

    SideBarComponent,
    SideBarAccountComponent,
    SideBarAdminComponent,
    SideBarOverlayComponent,
    MenuContainerComponent,
    MenuNavbarComponent,
    MenuAccountComponent,
    MenuAdminComponent,
    MenuIconComponent,
    MenuBurgerComponent,
    FooterInfoComponent,

    OverlayCartComponent,
    OverlayWishComponent,
    OverlaySearchComponent,
    OverlayMenuComponent,
    OverlayMobileSiteMenuComponent,
    OverlayIconBarComponent,
 
    ProductShopSingleComponent,
    PackageShopSingleComponent,
    PackageWidgetSingleComponent,
    ProductWidgetSingleComponent,
    ProductQuickViewSingleComponent,
    ProductQuickViewInfoSingleComponent,
    ProductQuickViewVariantSingleComponent,
    ProductBigImageComponent,
    ContactUsContainerComponent,
    BreadcrumbTitleComponent,
    IframeContainerComponent,
    LoadAllComponent,
    LoadMoreComponent,
    SubmitMessageComponent,
    SectionTitleComponent,
    TablePaginationComponent,
    TablePageCountComponent,
    CardCounterComponent,

    ImageHolderComponent,
    ModalImageUploadComponent,
    
    ButtonBlockDarkComponent,
    ButtonBlockDangerComponent,
    ButtonDarkComponent,
    ButtonDangerComponent,
    
    ContentNoBorderMarginComponent,
    ContentDetailsFormComponent,
    ContentPageWrapperComponent,

    DropdownDirective,
    SortTableDirective,
    ShowAuthedDirective,
    AutoFocusDirective,
    SafeUrlPipe
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AngularEditorModule,
    ModalModule,
    NgxOrgChartModule,
    RouterModule,

    LayoutOverlayContentComponent,
    LayoutLoaderComponent,

    SideBarComponent,
    SideBarAccountComponent,
    SideBarAdminComponent,
    SideBarOverlayComponent,
    MenuContainerComponent,
    MenuNavbarComponent,
    MenuAccountComponent,
    MenuAdminComponent,
    MenuIconComponent,
    MenuBurgerComponent,
    FooterInfoComponent,

    OverlayCartComponent,
    OverlayWishComponent,
    OverlaySearchComponent,
    OverlayMenuComponent,
    OverlayMobileSiteMenuComponent,
    OverlayIconBarComponent,    
    
    ProductShopSingleComponent,
    PackageShopSingleComponent,
    PackageWidgetSingleComponent,
    ProductWidgetSingleComponent,
    ProductQuickViewSingleComponent,
    ProductQuickViewInfoSingleComponent,
    ProductQuickViewVariantSingleComponent,
    ProductBigImageComponent,
    ContactUsContainerComponent,
    BreadcrumbTitleComponent,
    IframeContainerComponent,
    LoadAllComponent,
    LoadMoreComponent,
    SubmitMessageComponent,
    SectionTitleComponent,
    TablePaginationComponent,
    TablePageCountComponent,
    CardCounterComponent,

    ImageHolderComponent,
    ModalImageUploadComponent,

    ButtonBlockDarkComponent,
    ButtonBlockDangerComponent,
    ButtonDarkComponent,
    ButtonDangerComponent,

    ContentNoBorderMarginComponent,
    ContentDetailsFormComponent,
    ContentPageWrapperComponent,

    DropdownDirective,
    SortTableDirective,
    ShowAuthedDirective,
    AutoFocusDirective,
    SafeUrlPipe
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
  ],
  entryComponents: []
})
export class SharedModule { }
