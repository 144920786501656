import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class PageService {
  constructor(private apiService: ApiService) {}

  pingServer(): Observable<any> {
    return this.apiService.get('');
  }

  getInfo(id:string): Observable<any> {
    return this.apiService.get(`Api/Page/Info/${id}`);
  }

  updateContactUs(item:any): Observable<any> {
    return this.apiService.post(`Api/Page/ContactUs/`, item);
  }

  updateVisit(item:any): Observable<any> {
    return this.apiService.post(`Api/Page/Visit/`, item);
  }
}
