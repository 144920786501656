import { Component, OnInit, ViewEncapsulation, Injectable } from "@angular/core";
import { Product, Store, User } from "../../../core/models";
import { environment } from "../../../../environments/environment.prod";
import { ActivatedRoute } from "@angular/router";
import { HtmlService, StoreService, UserService } from "../../../core/services";
import { HostListener } from "@angular/core";


@Injectable()
@Component({
  selector: "app-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class LayoutComponent implements OnInit {
  IsPageShow: boolean = false;
  IsLandingPage: boolean = true;
  IsBlankPage: boolean = false;
  IsLoaderShow: boolean = true;
  IsAuthenticated: boolean = false;
  IsAdmin: boolean = false;
  IsMobile: boolean = false;

  Banner: string;
  Reference: string;
  CurrentUrl: string;
  CurrentTitle: string;

  UserData:User;
  StoreData:Store;
  Information: any = {
    WishList: [],
    CompareList: [],
    CartList: []
  };

  constructor(
    private _userService: UserService,
    private _htmlService: HtmlService,
    private _storeService: StoreService,
    private _activeRouter: ActivatedRoute
  ) {
    this._activeRouter.queryParams.subscribe(params => {
      this.CurrentUrl = window.location.origin;
      this.CurrentTitle = environment.title;
      setTimeout(() => this._htmlService.MainInit(), 100);
    });
    
    this._activeRouter.params.subscribe(params => {      
      setTimeout(() => this._htmlService.MainInit(), 100);
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.onPageSizeChange(event.target.innerWidth); 
  }

  ngOnInit() {
    this._userService.currentUser.subscribe(userData => { 
      this.IsAuthenticated = (userData._id == undefined ? false : true);
      this.IsAdmin = (userData.Role == 1 ? true : false);
      this.UserData = userData;      
    });  
    
    this.onPageSizeChange(window.innerWidth);
    this.GetInformation();
    this.GetStoreInformation();
  }

  ngAfterViewInit() {
    this._htmlService.MainInit();
  }

  onPageSizeChange(width: any) {
    this.IsMobile = (width <= 768);
  }

  onAddWishList(item: Product) {
    let record = this.Information.WishList.filter(x => x._id === item._id);
    if (record.length == 0) this.Information.WishList.push(item);    
    this._htmlService.UpdateAnalyticsEvent("add_to_wishlist", "wish", item.Name);
    this.SetInformation();
  }

  onRemoveWishList(item: Product) {
    this.Information.WishList = this.Information.WishList.filter(x => x._id !== item._id);
    this.SetInformation();
  }

  onAddCompareList(item: Product) {
    let record = this.Information.CompareList.filter(x => x._id === item._id);
    if (record.length == 0) this.Information.CompareList.push(item);
    this.Information.CompareList = this.Information.CompareList.slice(Math.max(this.Information.CompareList.length - 3, 0));
    this._htmlService.UpdateAnalyticsEvent("add_to_wishlist", "compare", item.Name);
    this.SetInformation();
  }

  onRemoveCompareList(item: Product) {
    this.Information.CompareList = this.Information.CompareList.filter(x => x._id !== item._id);    
    this.SetInformation();
  }

  onAddCartList(item: Product) {
    let record = this.Information.CartList.filter(x => x._id === item._id && x.SelectedProductColor === item.SelectedProductColor && x.SelectedProductSize === item.SelectedProductSize);
    if (record.length != 0) record.forEach(e => e.Quantity= e.Quantity+ 1);
    else this.Information.CartList.push(item);
    this._htmlService.UpdateAnalyticsEvent("add_to_cart", "cart", item.Name);
    this.SetInformation();
  }

  onRemoveCartList(item: Product) {
    this.Information.CartList = this.Information.CartList.filter(x => x._id !== item._id || x.SelectedProductColor !== item.SelectedProductColor || x.SelectedProductSize !== item.SelectedProductSize);
    this.SetInformation();
  }

  GetStoreInformation() {
    if(!this.StoreData) {
      this._storeService.getInfo().subscribe((data: any[]) => {
        this.Banner = data[0].Announcement || '';
        this.StoreData = data[0];       
      });
    }    
  }

  SetInformation() {
    localStorage.setItem('information', JSON.stringify(this.Information));
  }

  GetInformation() {
    let info = localStorage.getItem('information');
    if (info !== null) this.Information = JSON.parse(info);
  }

  GetCartCount() {
    return this.Information.CartList.reduce((acc, cur) => acc + (cur.Quantity), 0);
  }

  GetWishCount() {
    return this.Information.WishList.reduce((acc, cur) => acc + (cur.Quantity), 0);
  }
}
