import { Component, OnInit, ViewEncapsulation, HostListener, Inject, SimpleChanges } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { PageService, HtmlService, ShopService, AccountService } from "../../core/services";
import { Product, Filter, Category } from "../../core/models";
import { LayoutComponent } from "../../shared/components/layout/layout.component";

@Component({
  selector: "app-registration-info-page",
  templateUrl: "./registration-info.component.html",
  styleUrls: ["./registration-info.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class RegistrationInfoAuthComponent implements OnInit {
  PageTitle: string = "Activation";
  Model:any;
  AccountID: string;

  constructor(
    private _htmlService: HtmlService,
    private _accountService: AccountService,
    private _layout: LayoutComponent,
    private _activeRouter: ActivatedRoute
  ) {
    this._activeRouter.params.subscribe(params => {
      this.AccountID = params.id || '';
      this._layout.IsLoaderShow = true;
      this._layout.IsPageShow = true;

      this._htmlService.UpdateMetaTags({ title: this.PageTitle});
      this._htmlService.UpdateAnalyticsEvent("view_item", "shop", this.PageTitle);
      this._layout.IsLoaderShow = false;
      setTimeout(() => this._htmlService.MainInit(), 100);     
      this.GetInformation();    
    });
  }

  ngOnInit() {
    this._layout.IsLandingPage = false;
    this._layout.IsBlankPage = false;
  }

  GetInformation() {
    this._accountService.getInfo(this.AccountID).subscribe((data: any[]) => {
      this._layout.IsLoaderShow = false;
      this.Model = data[0];
      this.ActivateAccount();
    });
  }

  ActivateAccount() {
    let item = {
      _id: this.Model._id,
      ID: this.Model._id,
      UserID: this.Model._id,
      IsActivate: 1,
      Remarks: this.PageTitle,
      Module: 'ACTIVATE ACCOUNT'
    };

    this._accountService.updateInfo(item).subscribe((data: any) => {
      this.Model.IsSuccessSubmit = true;
      setTimeout(() =>  this.Model.IsSuccessSubmit = false, 5000);   
    },
    (error: any) => { 
      this.Model.IsErrorSubmit = true;
    });
  }
}
