import { Component, OnInit, Input} from "@angular/core";
import { HtmlService, User } from "src/app/core";

@Component({
  selector: "app-overlay-mobile-site-menu",
  templateUrl: "./mobile-site-menu.component.html",
  styleUrls: ["./mobile-site-menu.component.css"]
})
export class OverlayMobileSiteMenuComponent implements OnInit {  
  @Input() IsAuthenticated: boolean = false;
  @Input() Model: User;

  constructor(
    private _htmlService: HtmlService
  ) {
  }

  ngOnInit() {   
    this._htmlService.DlMenuInit();
  }

  onClick() {
    this._htmlService.DlMenuInit();
  }

  onClose() {
    this._htmlService.OverlayRemove();
  }

}
