export const environment = {  
  api_url: window['api'] || '/',
  messenger: window['messenger'] || '/',  
  fbpage: window['fb:page'] || '/', 
  twitterpage: window['twitter:page'] || '/', 

  errorMessage: 'Something bad happened; please try again later.',
  title: window['title'] || '',
  description: window['description'] || '',
  keywords: window['keywords'] || '',
  email: window['email'] || '',  
  phone: window['phone'] || '', 
  image: window['image'] || '', 
  freeshipping: window['freeshipping'] || '', 
  dateFormat: 'MM/dd/yyyy',
  production: false
};
