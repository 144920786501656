import { Injectable } from "@angular/core";
import { Ngxalert } from 'ngx-dialogs';

@Injectable()
export class DialogService {
    Dialog: any = new Ngxalert;
    constructor() { }

    public close() {
        document.getElementById('ngxdialog-0').remove();
    }

    public confirm(message: string, title: string = 'Information', confirm: Function, cancel: Function) {
        this.Dialog.create({
            title: '',
            strict: true,
            message: message
        });
    }

    public alert(message: string, title: string = 'Information') {
        this.Dialog.create({
            title: '',
            strict: true,
            message: message
        });
    }

    public error(message: string, title: string = 'Error') {
        this.Dialog.create({
            title: title,
            strict: true,
            message: message,
            type:'M',
            customCssClass: 'msg-error'
        });
    }
}
