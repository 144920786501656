import { Injectable, Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from '@angular/platform-browser'

@Injectable()
@Pipe({ name: 'safeurl' })
export class SafeUrlPipe implements PipeTransform {
    constructor(private _sanitizer: DomSanitizer) { }
    transform(url) {
        return this._sanitizer.bypassSecurityTrustResourceUrl(url);
    }
}