import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-table-pagination",
  templateUrl: "./table-pagination.component.html",
  styleUrls: ["./table-pagination.component.css"]
})
export class TablePaginationComponent implements OnInit {
  @Input() TotalRecords = 0;
  @Input() RecordsPerPage = 0;
  @Output() OnPageChange: EventEmitter<number> = new EventEmitter();

  public Pages: number[] = [];
  ActivePage: number;
  PageCount:number = 0;
  PageLimit:number = 10;
  IsVisible:boolean = true;

  constructor() {
  }

  ngOnInit() {
  }

  ngOnChanges(): any {
    this.PageCount = this.getPageCount();
    this.Pages = this.getArrayOfPage(this.PageCount, 0);
    this.ActivePage = 1;
    this.IsVisible = (this.TotalRecords > this.RecordsPerPage);
  }

  private getPageCount(): number {
    let TotalPage = 0;
    if (this.TotalRecords > 0 && this.RecordsPerPage > 0) {
      const pageCount = this.TotalRecords / this.RecordsPerPage;
      const roundedPageCount = Math.floor(pageCount);
      TotalPage = roundedPageCount < pageCount ? roundedPageCount + 1 : roundedPageCount;
    }
    return TotalPage;
  }

  private getArrayOfPage(pageCount: number, offsetCount:number): number[] {
    const PageArray = [];
    if (pageCount > 0) {
      for (let i = 1; i <= pageCount; i++) {
        let PageNo = (i + offsetCount);
        if(i <= this.PageLimit && PageNo <= this.PageCount) PageArray.push(PageNo);
        else break;
      }
    }
    return PageArray;
  }

  onClickPage(pageNumber: number): void {   
    let LastPage = this.Pages[this.Pages.length - 1] | 0;
    let FirstPage = this.Pages[0] | 0;

    if(LastPage >= pageNumber && FirstPage <= pageNumber && pageNumber != 0) {
      this.ActivePage = pageNumber;
      this.OnPageChange.emit(this.ActivePage);
    }
    else if (pageNumber < this.PageCount && pageNumber != 0) {
      let offset = (Math.floor(pageNumber / this.PageLimit) * this.PageLimit);
      this.Pages = this.getArrayOfPage(this.PageCount, (pageNumber == this.PageLimit? 0 : offset));
      this.ActivePage = pageNumber;
      this.OnPageChange.emit(this.ActivePage);
    }
  }
}
