import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { PageService, HtmlService, ShopService, StoreService, UserService, AccountService } from "../../core/services";
import { LayoutComponent } from "../../shared/components/layout/layout.component";

@Component({
  selector: 'app-auth-page',
  templateUrl: './auth.component.html',
  styleUrls: ["./auth.component.css"]
})
export class AuthComponent implements OnInit {
  PageTitle: string = "Login";
  Model: any = {
    UserName: '',
    Password: '',
    IsRemember: false,
    IsErrorSubmit: false,
    IsSuccessSubmit: false,
  }

  constructor(
    private _htmlService: HtmlService,
    private _userService: UserService,
    private _accountService: AccountService,
    private _layout: LayoutComponent,
    private _router: Router,
    private _activeRouter: ActivatedRoute
  ) {
    this._activeRouter.queryParams.subscribe(params => {
      this._layout.IsLoaderShow = true;
      this._layout.IsPageShow = true;

      this._htmlService.UpdateMetaTags({ title: this.PageTitle });
      this._htmlService.UpdateAnalyticsEvent("view_item", "page", this.PageTitle);
      setTimeout(() => this._htmlService.MainInit(), 100);
      this._layout.IsLoaderShow = false;
    });
  }

  ngOnInit() {
    this._layout.IsLandingPage = false;
    this._layout.IsBlankPage = false;
  }

  onSubmit() {
    let dto = {
      UserName: this.Model.UserName,
      Password: this.Model.Password,
      IsRemember: this.Model.IsRemember,
    };

    this.Model.IsErrorSubmit = false;
    this.Model.IsSuccessSubmit = false;
    this._userService.attemptAuth(dto).subscribe((data: any) => {
      this._layout.UserData = data;
      this.Model.UserName = '';
      this.Model.Password = '';
      this.Model.IsRemember = false;
      this.Model.IsSuccessSubmit = true;

      this.navigateUser(data._id);
      setTimeout(() => this.Model.IsSuccessSubmit = false, 2000);
      this._layout.IsLoaderShow = true;
    },
      (error: any) => {
        this.Model.IsErrorSubmit = true;
        setTimeout(() => this.Model.IsErrorSubmit = false, 2000);
      });
  }

  navigateUser(id: any) {
    this._accountService.getRole(id).subscribe((role: any) => {
      let roleId = role[0].Role;
      if (roleId == 1) setTimeout(() => this._router.navigateByUrl('/admin/dashboard'), 1000);
      else setTimeout(() => this._router.navigateByUrl('/account/dashboard'), 1000);
    });
  }
}
