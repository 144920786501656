import { Component, OnInit, ViewEncapsulation, Injectable } from "@angular/core";

@Injectable()
@Component({
  selector: "app-loader",
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class LayoutLoaderComponent implements OnInit { 

  constructor(   
  ) {   
  }

  ngOnInit() {
  }
}
