import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { environment } from "../../../../../environments/environment.prod";
import { Store } from "../../../../core/models";

@Component({
  selector: "app-footer-info",
  templateUrl: "./info-footer.component.html",
  styleUrls: ["./info-footer.component.css"]
})
export class FooterInfoComponent implements OnInit {
  @Input() IsAuthenticated: boolean = false;
  @Input() Model: Store;
  @Output() OnViewItem = new EventEmitter();

  CurrentTitle: string;
  FBPage: string;
  TwitterPage: string;
  SubscriptionEmail: string;

  constructor(
  ) {
  }

  ngOnInit() {
    this.CurrentTitle = environment.title;
    this.FBPage = environment.fbpage;
    this.TwitterPage = environment.twitterpage;
  }
}
