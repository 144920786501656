import { ViewAccountComponent } from './view-account.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from './auth.component';
import { AuthGuard, NoAuthGuard } from './../../core/guards';
import { AuthResolver } from './auth-resolver.service';
import { LostPasswordAuthComponent } from './lost-passwod.component';
import { RegistrationAuthComponent } from './registration.component';
import { LogoutComponent } from './logout.component';
import { RegistrationInfoAuthComponent } from './registration-info.component';

const routes: Routes = [
  {
    path: 'login',
    component: AuthComponent,
    canActivate: [NoAuthGuard]
  },
  {
    path: 'logout',
    component: LogoutComponent,
    resolve: {
      isAuthenticated: AuthResolver
    }
  },
  {
    path: 'lost-password',
    component: LostPasswordAuthComponent,
    canActivate: [NoAuthGuard]
  },
  {
    path: 'view-account',
    component: ViewAccountComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'activation/:id',
    component: RegistrationInfoAuthComponent,
    resolve: {
      isAuthenticated: AuthResolver
    }
  },
  {
    path: 'register',
    component: RegistrationAuthComponent,
    resolve: {
      isAuthenticated: AuthResolver
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule {}
