import { Component, OnInit, Input } from "@angular/core";
import { HtmlService, StoreService } from "../../../core/services";

@Component({
  selector: "app-contactus-container",
  templateUrl: "./contactus-container.component.html",
  styleUrls: ["./contactus-container.component.css"]
})
export class ContactUsContainerComponent implements OnInit {
  @Input() Model: any;
  @Input() IsFooter: boolean = true;

  Info : any = {
    Name : '',
    Email : '',
    ContactNo : '',
    Subject : '',
    Message : '',
    IsErrorName : false,
    IsErrorEmail : false,
    IsErrorContactNo : false,
    IsErrorMessage : false,
    IsErrorSubmit : false,
    IsSuccessSubmit : false,
  }

  constructor(
    private _storeService: StoreService,
    private _htmlService: HtmlService,
  ) {
  }

  ngOnInit() {   
  }

  IsEmail(item) : boolean {
    return this._htmlService.IsEmail(item);
  }

  onSubmit() {
    if (!this.IsEmail(this.Info.Email) || this.Info.Email == undefined) this.Info.IsErrorEmail = true;
    if (this.Info.Name == '' || this.Info.Name == undefined) this.Info.IsErrorName = true;
    if (this.Info.ContactNo == '' || this.Info.ContactNo == undefined) this.Info.IsErrorContactNo = true;
    if (this.Info.Message == '' || this.Info.Message == undefined) this.Info.IsErrorMessage = true;  
    if (this.Info.IsErrorEmail || this.Info.IsErrorName || this.Info.IsErrorContactNo  || this.Info.IsErrorMessage) return;

    let dto = {
      ID : this._htmlService.GenerateGUID(),
      UserID : this._htmlService.GenerateGUID(),
      Name: this.Info.Name,
      Email: this.Info.Email,
      ContactNo: this.Info.ContactNo,
      Subject: this.Info.Subject,
      Message: this.Info.Message,
    };  

    this.Info.IsErrorSubmit = false;
    this.Info.IsSuccessSubmit = false;
    this._storeService.contactUs(dto).subscribe((data: any) => {
      this.Info.Name = '';
      this.Info.Email = '';
      this.Info.ContactNo = '';
      this.Info.Message = '';
      this.Info.IsSuccessSubmit = true;
      setTimeout(() =>  this.Info.IsSuccessSubmit = false, 2000);   
    },
    (error: any) => { 
      this.Info.IsErrorSubmit = true;
    });
  }
}
