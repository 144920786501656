import { Component, Input, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "app-image-holder",
  templateUrl: "./image-holder.component.html",
  styleUrls: ["./image-holder.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class ImageHolderComponent { 
  @Input() FilePath : string;
}
