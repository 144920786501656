import { Component, OnInit, Input, Output, EventEmitter} from "@angular/core";
import { interval } from "rxjs";
import { Product } from "../../../core/models";
import { LayoutComponent } from "../layout/layout.component";
import { Router } from "@angular/router";
import { HtmlService } from "../../../core/services";

@Component({
  selector: "app-overlay-cart",
  templateUrl: "./cart-overlay.component.html",
  styleUrls: ["./cart-overlay.component.css"]
})
export class OverlayCartComponent implements OnInit {  
  @Input() Model: Product[] = [];  
  Currency:string = "";

  constructor(
    private _htmlService: HtmlService,
    private _layout: LayoutComponent,
    private _router: Router,
  ) {
  }

  ngOnInit() { 
  }

  ngOnChanges() {
    if(this.Model.length != 0) this.Currency = (this.Model[0].Currency) || '';  
  }

  GetSubTotal() {
    return this.Model.reduce((acc, cur) => acc + (cur.Quantity* cur.Price), 0);
  }

  GetDiscountTotal() {
    let total = this.GetSubTotal();
    if (total > 200000 && total <= 400000) return total * 0.5;
    else if (total > 400000 && total <= 500000) return total * 0.55;    
    else if (total > 500000) return total * 0.6;
    else return 0;
  }

  onViewItem(item) {
    setTimeout(() => this._htmlService.OverlayRemove(), 100);
    this._router.navigateByUrl('/shop/product/' + item._id);
  }

  onViewCart() {
    setTimeout(() => this._htmlService.OverlayRemove(), 100);
    this._router.navigateByUrl('/shop/cart');
  }

  onViewCheckOut() {
    setTimeout(() => this._htmlService.OverlayRemove(), 100);
    this._router.navigateByUrl('/shop/checkout');
  }

  onRemoveItem(item) {
    this._layout.onRemoveCartList(item);
  }
}
