import { Component, OnInit, ViewEncapsulation, Injectable, Input } from "@angular/core";
import { Product, Social, Store, User } from "../../../core/models";

@Injectable()
@Component({
  selector: "app-overlay-content",
  templateUrl: "./overlay-content.component.html",
  styleUrls: ["./overlay-content.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class LayoutOverlayContentComponent implements OnInit {
  @Input() User: User;
  @Input() Store: Store;
  @Input() Social: Social[];
  @Input() WishList: Product[];
  @Input() CartList: Product[];
  @Input() IsAuthenticated: boolean = false;
  @Input() IsAdmin: boolean = false;
  @Input() IsLandingPage:boolean = true;

  constructor(
  ) {
  }

  ngOnInit() {
  }
}
