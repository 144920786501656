import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class OrderService {
  constructor(private apiService: ApiService) { }
 
  getInfo(id:any): Observable<any> {
    return this.apiService.get(`Api/Order/Info/${id}`);
  }
  
  getList(params:any): Observable<any> {
    const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.apiService.get(`Api/Order/List?${queryString}`);
  }

  newInfo(order:any): Observable<any> {
    return this.apiService.post(`Api/Order/New`, order);
  }
  
  updateInfo(order:any): Observable<any> {
    return this.apiService.post(`Api/Order/Info`, order);
  }

  infoValidate(order:any): Observable<any> {
    return this.apiService.post(`Api/Order/Validate/NEW ORDER`, order);
  }
  
  deleteInfo(order:any): Observable<any> {
    return this.apiService.delete(`Api/Order/Info`, order);
  }

  getAdminList(params:any): Observable<any> {
    const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return this.apiService.get(`Api/Order/Admin/List?${queryString}`);
  }

  paypal(id:any): Observable<any> {
    return this.apiService.get(`Service/Payment/Paypal/${id}`);
  }
}
