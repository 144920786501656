import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Product, Size } from "../../../core/models";

@Component({
  selector: "app-product-quickview-variant",
  templateUrl: "./quickview-product-variant.component.html",
  styleUrls: ["./quickview-product-variant.component.css"]
})
export class ProductQuickViewVariantSingleComponent implements OnInit {
  @Input() Model: Product;
  @Input() SizeList: Size[];
  @Output() OnViewColor = new EventEmitter();
  @Output() OnViewSize = new EventEmitter();
  
  constructor() {
  }

  ngOnInit() {
  }

  onColorClick(item:any) {
    this.OnViewColor.emit(item);
  }

  onSizeClick(item:any) {
    this.OnViewSize.emit(item);
  }
}
