import { NgModule, ErrorHandler } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpTokenInterceptor, GlobalErrorInterceptor } from "./interceptors";
import { AuthGuard, NoAuthGuard } from "./guards";
import { ApiService, JwtService, UserService } from "./services";
import { DialogService } from "./services/dialog.service";

@NgModule({
  imports: [CommonModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorInterceptor },
    ApiService,
    AuthGuard,
    DialogService,
    NoAuthGuard,
    JwtService,
    UserService
  ],
  declarations: []
})
export class CoreModule {}
