import { Injectable, Injector } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";

import { JwtService } from "../services";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(
    private _jwtService: JwtService,
  ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const headersConfig = {};
    const token = this._jwtService.getToken();
    if (token) {
      headersConfig["Authorization"] = `${token}`;
    }

    const request = req.clone({ setHeaders: headersConfig });
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 500) {
          console.log(error.error)
          return throwError(error);
        }
        return throwError(environment.errorMessage);
      })
    );
  }
}
