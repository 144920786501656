import { Component, OnInit, Input, Output, EventEmitter} from "@angular/core";

@Component({
  selector: "app-load-more",
  templateUrl: "./load-more.component.html",
  styleUrls: ["./load-more.component.css"]
})
export class LoadMoreComponent implements OnInit {
  @Output() OnLoadItem = new EventEmitter();

  constructor(
  ) {
  }

  ngOnInit() {   
  }

  onLoadItem() {
    this.OnLoadItem.emit();
  }
}
