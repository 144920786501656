import { Component, OnInit, Input, Output, EventEmitter} from "@angular/core";
import { interval } from "rxjs";
import { Product } from "../../../core/models";

@Component({
  selector: "app-product-widget-single",
  templateUrl: "./widget-single-product.component.html",
  styleUrls: ["./widget-single-product.component.css"]
})
export class ProductWidgetSingleComponent implements OnInit {  
  @Input() Model: Product;  
  @Output() OnViewItem = new EventEmitter();
  @Output() OnSearchItem = new EventEmitter();

  Ratings: string[];

  constructor() {
  }

  ngOnInit() { 
  }

  ngAfterViewInit() {
    this.GetRating();
  }

  GetRating() {
    this.Ratings = [];
    for (let i = 0; i < 5; i++) {
      this.Ratings.push((i <= this.Model.Rating? 'ion-android-star' : 'ion-android-star-outline'));
    }
  }

  onViewItem() {
    this.OnViewItem.emit(this.Model);
  }

  onSearchCategory(item) {
    this.OnSearchItem.emit({ type: 'category', search : item });
  }

  onSearchTags(item) {
    this.OnSearchItem.emit({ type: 'tag', search : item });
  }
}
