import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { PageService, HtmlService, ShopService, StoreService, UserService, AccountService } from "../../core/services";
import { LayoutComponent } from "../../shared/components/layout/layout.component";

@Component({
  selector: 'app-lost-password-auth-page',
  templateUrl: './lost-passwod.component.html',
  styleUrls: ["./lost-passwod.component.css"]
})
export class LostPasswordAuthComponent implements OnInit {
  PageTitle : string = "Lost Password";
  Model : any = {
    UserName : '',
    IsErrorSubmit : false,
    IsSuccessSubmit : false,
  }

  constructor(
    private _htmlService: HtmlService,
    private _accountService: AccountService,
    private _layout: LayoutComponent,
    private _router: Router,
    private _activeRouter: ActivatedRoute
  ) {
    this._activeRouter.queryParams.subscribe(params => {
      this._layout.IsLoaderShow = true;
      this._layout.IsPageShow = true; 

      this._htmlService.UpdateMetaTags({ title: this.PageTitle});
      this._htmlService.UpdateAnalyticsEvent("view_item", "page", this.PageTitle); 
      setTimeout(() => this._htmlService.MainInit(), 100);
      this._layout.IsLoaderShow = false;
    });
  }

  ngOnInit() {
    this._layout.IsLandingPage = false;
    this._layout.IsBlankPage = false;
  }

  onSubmit() {
    let dto = {
      ID : this._htmlService.GenerateGUID(),
      UserID : this._htmlService.GenerateGUID(),
      UserName : this.Model.UserName,
    };  

    this.Model.IsErrorSubmit = false;
    this.Model.IsSuccessSubmit = false;
    this._accountService.forgotPassword(dto).subscribe((data: any) => {
      this.Model.UserName = '';
      this.Model.IsSuccessSubmit = true;    
      setTimeout(() =>  this.Model.IsSuccessSubmit = false, 5000); 
    },
    (error: any) => { 
      this.Model.IsErrorSubmit = true;
      setTimeout(() =>  this.Model.IsErrorSubmit = false, 2000);   
    });
  }
}
