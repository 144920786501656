import { Component, OnInit, ViewEncapsulation, HostListener, Inject, Input } from "@angular/core";

@Component({
  selector: "app-step-registration",
  templateUrl: "./step-registration.component.html",
  styleUrls: ["./step-registration.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class StepRegistrationComponent implements OnInit { 
  @Input() StepNo: number = 1;

  ngOnInit() {      
  }
}
