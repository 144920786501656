import { Component, OnInit, Input, EventEmitter, Output} from "@angular/core";
import { HtmlService, User } from "src/app/core";

@Component({
  selector: "app-card-counter",
  templateUrl: "./card-counter.component.html",
  styleUrls: ["./card-counter.component.css"]
})
export class CardCounterComponent implements OnInit {    
  @Input() Model: any;
  @Input() Currency: string;
  @Output() OnViewItem = new EventEmitter();

  constructor(
  ) {
  }

  ngOnInit() {  
  }

  onViewItem() {
    this.OnViewItem.emit(this.Model);
  }
}
