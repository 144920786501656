import { Component, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "app-content-no-border-margin",
  templateUrl: "./content-no-border-margin.component.html",
  styleUrls: ["./content-no-border-margin.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class ContentNoBorderMarginComponent  { 

}
