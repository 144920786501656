import { Component, OnInit, Input} from "@angular/core";
import { User } from "../../../../core/models";

@Component({
  selector: "app-menu-admin",
  templateUrl: "./menu-admin.component.html",
  styleUrls: ["./menu-admin.component.css"]
})
export class MenuAdminComponent implements OnInit {  
  @Input() IsAuthenticated: boolean = false;
  @Input() Model: User;

  constructor(
  ) {
  }

  ngOnInit() {   
  }
}
