import { Component, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "app-content-page-wrapper",
  templateUrl: "./content-page-wrapper.component.html",
  styleUrls: ["./content-page-wrapper.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class ContentPageWrapperComponent  { 
}
