import { Component, OnInit, ViewEncapsulation, HostListener, Inject, Input } from "@angular/core";

@Component({
  selector: "app-section-title",
  templateUrl: "./section-title.component.html",
  styleUrls: ["./section-title.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class SectionTitleComponent implements OnInit { 
  @Input() PageTitle: string = "";
  @Input() Text: string = "";

  ngOnInit() {      
  }
}
