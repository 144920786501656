import { Component, OnInit, Input, Output, EventEmitter} from "@angular/core";
import { interval } from "rxjs";
import { Product, Size } from "../../../core/models";

@Component({
  selector: "app-product-shop-single",
  templateUrl: "./shop-single-product.component.html",
  styleUrls: ["./shop-single-product.component.css"]
})
export class ProductShopSingleComponent implements OnInit {  
  @Input() Model: Product;  
  @Output() OnViewItem = new EventEmitter();
  @Output() OnWishlist = new EventEmitter();
  @Output() OnCompare = new EventEmitter();
  @Output() OnQuickView = new EventEmitter();
  @Output() OnAddToCart = new EventEmitter();
  
  CartText:string = "add to Cart";
  SizeList : Size[] = [];
  IsAddCart: boolean = false;

  constructor() {
  }

  ngOnInit() { 
  }

  onViewItem() {
    this.OnViewItem.emit(this.Model);
  }

  onWishlist() {
    this.OnWishlist.emit(this.Model);
  }

  onCompare() {
    this.OnCompare.emit(this.Model);
  }

  onQuickView() {
    this.OnQuickView.emit(this.Model);
  }

  onAddToCart() {
    let item: any = {};
    item._id = this.Model._id;
    item.SKU = this.Model.SKU;
    item.Name = this.Model.Name;
    item.Currency = this.Model.Currency;
    item.Price = this.Model.Price;
    item.ShortDescription = this.Model.ShortDescription;
    item.FullDescription = this.Model.FullDescription;    
    item.Image = this.Model.Image;
    item.Quantity= this.Model.Quantity;   
    item.ShippingFee = this.Model.ShippingFee;
    
    if(this.Model.Variation != undefined) {
      item.Variation = [this.Model.Variation[0]];
      item.ThumbImage = [item.Variation[0].Image];
      item.SelectedProductSize = item.Variation[0].Size[0].Name;
      item.SelectedProductColor = item.Variation[0].Color;
    }  

    this.IsAddCart = true;
    this.CartText = "added!"
    setTimeout(() => { this.IsAddCart = false;}, 500);
    setTimeout(() => { this.CartText = "add to Cart"}, 1500);
    this.OnAddToCart.emit(item);
  }

  onColorHover(item) {
    this.Model.Variation.forEach(x => x.Active = false);
    this.SizeList = item.Size;
    item.Active = true;    
  } 
}
