import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-table-page-count",
  templateUrl: "./table-page-count.component.html",
  styleUrls: ["./table-page-count.component.css"]
})
export class TablePageCountComponent implements OnInit {
  @Input() TotalRecords = 0;
  @Input() RecordsPerPage = 0;
  @Input() ActivePage = 0;
  @Output() OnPageChange: EventEmitter<number> = new EventEmitter();

  FromPage: number = 1;
  ToPage: number;
  PagePerRd: number = 10;
  IsVisible:boolean = true;

  constructor() {
  }

  ngOnInit() {
  }

  ngOnChanges(): any {
    this.PagePerRd = this.RecordsPerPage;
    this.ActivePage = ((this.ActivePage == 0 || this.ActivePage == 1) ? 1 : this.ActivePage);
    this.IsVisible = (this.TotalRecords >= this.PagePerRd);
    this.GetShowingNo();
  }

  GetShowingNo() {
    let PageNo =  (this.ActivePage * this.RecordsPerPage);
    this.FromPage =(this.ActivePage == 1? 1 : (PageNo - this.RecordsPerPage));
    this.ToPage = (PageNo >= this.TotalRecords ? this.TotalRecords : PageNo);
  }

  onItemChange(): void {
    this.OnPageChange.emit(this.PagePerRd);
  }
}
