import { Component, OnInit, Input, Output, EventEmitter} from "@angular/core";

@Component({
  selector: "app-load-all",
  templateUrl: "./load-all.component.html",
  styleUrls: ["./load-all.component.css"]
})
export class LoadAllComponent implements OnInit {
  @Output() OnLoadItem = new EventEmitter();

  constructor(
  ) {
  }

  ngOnInit() {   
  }

  onLoadItem() {
    this.OnLoadItem.emit();
  }

}
