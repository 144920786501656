import { Component, OnInit, Input, Output, EventEmitter} from "@angular/core";
import { Social } from "../../../core/models";
@Component({
  selector: "app-overlay-icon-bar",
  templateUrl: "./icon-bar-overlay.component.html",
  styleUrls: ["./icon-bar-overlay.component.css"]
})
export class OverlayIconBarComponent implements OnInit {  
  @Input() Model: Social[];

  constructor() {
  }

  ngOnInit() {   
  }
}
