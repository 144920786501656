import { Component, OnInit, ViewEncapsulation, HostListener, Inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { PageService, HtmlService } from "../../core/services";
import { LayoutComponent } from "../../shared/components/layout/layout.component";

@Component({
  selector: "app-not-found-page",
  templateUrl: "./not-found.component.html",
  styleUrls: ["./not-found.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class NotFoundComponent implements OnInit { 

  constructor(
    private _htmlService: HtmlService,
    private _layout : LayoutComponent,
    private _activeRouter: ActivatedRoute
  ) {
    this._activeRouter.queryParams.subscribe(params => {
      this._layout.IsLoaderShow = true;
      this._layout.IsPageShow = true; 

      this._htmlService.UpdateMetaTags({ title : 'Not Found', description: 'Not Found'});
      setTimeout(() => this._htmlService.MainInit(), 100);
      this._layout.IsLoaderShow = false;
    });
  }

  ngOnInit() {   
    this._layout.IsLandingPage = false;
    this._layout.IsBlankPage = false;
  }
}
